export default function ExclusiveIconSVG() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.64732 21.0429C4.88839 21.3 5.16964 21.4286 5.49107 21.4286H15.9375C16.2589 21.4286 16.5402 21.3 16.7812 21.0429C17.0223 20.7857 17.1429 20.4857 17.1429 20.1429V8.97856L12.7433 4.28571H5.49107C5.16964 4.28571 4.88839 4.41428 4.64732 4.67142C4.40625 4.92856 4.28571 5.22856 4.28571 5.57142V20.1429C4.28571 20.4857 4.40625 20.7857 4.64732 21.0429ZM7.95918 13.8095H14.3878V12.8571H7.95918V13.8095ZM7.95918 17.619H14.3878V16.6667H7.95918V17.619ZM12.219 10.098V6.19047H5.20408V10.098V20.4762H16.2245V10.098H12.219Z" fill="#323842" />
      <path d="M18 6.85716L18.5246 5.66746L19.7143 5.14287L18.5246 4.61828L18 3.42859L17.4754 4.61828L16.2857 5.14287L17.4754 5.66746L18 6.85716Z" fill="#323842" />
      <path d="M10.2857 12L10.548 11.4051L11.1429 11.1428L10.548 10.8806L10.2857 10.2857L10.0234 10.8806L9.42857 11.1428L10.0234 11.4051L10.2857 12Z" fill="#323842" />
      <path fillRule="evenodd" clipRule="evenodd" d="M24 0V24H0L24 0Z" fill="#D8D8D8" fillOpacity="0.0248306" />
    </svg>
  )
}
